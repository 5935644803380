@media screen and (max-width:768px) {
  div.cart-dropdown {
    width: 200px;
    height: 300px;
    top: 75px;
    right: 30px;

    button.custom-button:hover{
      padding: 0 32px;
    }
  }
}

.cart-dropdown {
  position: absolute;
  width: 240px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 90px;
  right: 40px;
  z-index: 5;

  .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
  }

  .empty-cart{
    font-size: 18px;
    margin: 50px auto;
  }

  button {
    margin-top: auto;
  }
}
