$breakpoint-tablet:768px;

@media screen and (max-width:$breakpoint-tablet) {
  div.header{
    height: 60px;
    padding: 10px;
    padding-right: 0;
    margin-bottom: 30px;

    .logo-container {
      width: 50px;
      padding: 0;
    }
    
    div.options{
      width: 80%;
    }

    div.options a.option {
      padding: 10px;
    }
  }


}

.header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  .logo-container {
    height: 100%;
    width: 70px;
    padding: 25px;
  }

  .options {
    width: 50%;
    height: 100%;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      padding: 10px 15px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

